.sort-and-filter-container {
    display: flex;
    margin-top: 0.5rem;
}

.search-section-background {
  background-color: white !important;
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.sort-dropdown-parent {
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.sort-dropdown-text {
  font-size: 16px;
  margin-left: 1rem;
  margin-right: 0.5rem;
  float: right;
  text-align: right;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  align-content: flex-end;
  white-space: nowrap;
}

.sort-dropdown-menu {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dropdown-tags-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    margin-left: 1.1rem;
  }

.card-container { 
  display: flex; 
  align-items: 'center';
  justify-content: 'center';
}

.card-contents-container {
  display: table;
  vertical-align: middle;
  width: 100%;
}

.card-contents-cell-left {
  display: table-cell;
  vertical-align: top;
  text-align: left;
  width: 50%;
}

.card-contents-cell-right {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}

.card-ratings-container {
  display: table;
  width: 100%;
}

.card-ratings-stars {
  display: table;
  vertical-align: middle;
  padding-bottom: 0.3rem;
}

.card-header {
  font-size: x-large;
  font-weight: bold;
  color: black;
  padding-bottom: 2%;
}

.card-meta {
  font-size: large;
}

.card-description {
  color: black;
  width: 80%;
}

.course-review-item {
  width: 100%;
  height: 100%;
}

.item-container {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding: 0;
  vertical-align: middle;
  top: 50%;
}


.item-contents-container {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  text-align: center;
  text-justify: center;
  width: 100%;
  height: 100%;
  color: black;
  vertical-align: middle;

  padding-bottom: 9px;
  /*padding-top: 9px;*/
  border: 0px;
  border-bottom: 2px solid lightgray;

  transition: transform .1s;
  
}

.item-contents-container:hover {
  transform: scale(1.01);
}

.stretched.row {
  padding: 0;
}


.item-contents-code {
  margin-left: 1.2%;
  text-align: left;
  width: 8%;
  vertical-align: middle;
  
}
.item-header {
  font-weight: bold;
  color: black;
  padding-bottom: 2%;
  vertical-align: middle;
  
}

.item-contents-name {
  text-align: left;
  width: 22%;
  vertical-align: middle;
}

.item-contents-tags {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 20%;
  top: -20%;
}

.item-ratings-stars {
  width: 14.8%;  
}

.item-contents-enjoyment {
  width: 7.7%;  
}
.item-contents-usefulness {
  width: 7.7%;  
}
.item-contents-manageability {
  width: 7.7%;  
}

.item-contents-review-num {
  width: 7.7%;  
}


.topbar {
  background-color: #5D88E4;
  border-radius: 7px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  margin-bottom: 2.5%;
}

.topbar-contents-container {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.topbar-header {
  font-weight: bold;
  color: white;
  font-size: 0.9em;
}

.topbar-item {
  position: relative;
  padding: 2% 0 2% 0;
  text-align: center;

}

.topbar-contents-code {
  margin-left: 3.2%;
  width: 7%;
}
.topbar-contents-name {
  margin-left: 2.5%;
  text-align: center;
  width: 20%;
}
.topbar-contents-tag {
  margin-left: 2%;
  width: 20%;
}
.topbar-contents-rating {
  margin-left: 2%;
  width: 12%;
}
.topbar-contents-enjoyability {
  margin-left: 1.8%;
  width: 8.5%;
}
.topbar-contents-usefulness {
  width: 8%;
}
.topbar-contents-manageability {
  width: 8%;
}

.topbar-contents-num {
  width: 10%;
}


.ui.checkbox.checkbox-major input:checked~.box:before,
.ui.checkbox.checkbox-major input:checked~label:before,
.ui.checkbox.checkbox-major input:focus:checked~.box:before,
.ui.checkbox.checkbox-major input:focus:checked~label:before,
.ui.label.major,
.ui.dropdown .menu.major {
  background-color: var(--major-tags);
}

.ui.checkbox.checkbox-term input:checked~.box:before,
.ui.checkbox.checkbox-term input:checked~label:before,
.ui.checkbox.checkbox-term input:focus:checked~.box:before,
.ui.checkbox.checkbox-term input:focus:checked~label:before,
.ui.label.term,
.ui.dropdown .menu.term {
  background-color: var(--term-tags);
}

.ui.checkbox.checkbox-prefix input:checked~.box:before,
.ui.checkbox.checkbox-prefix input:checked~label:before,
.ui.checkbox.checkbox-prefix input:focus:checked~.box:before,
.ui.checkbox.checkbox-prefix input:focus:checked~label:before,
.ui.label.prefix,
.ui.dropdown .menu.prefix {
  background: var(--prefix-tags);
}

.ui.label.undergraduate {
  background: var(--undergraduate);
}

.ui.label.postgraduate {
  background: var(--postgraduate);
}

.ui.dropdown .menu .item {
  background: white;
}

.title-wrapper {
  min-height: 90vh;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.title-wrapper h1 {
  font-family: 'nevis', sans-serif;
  font-size: 7rem;
}

.title-wrapper h2 {
  font-family: 'nevis', sans-serif;
  margin-bottom: 5rem;
  letter-spacing: 0rem;
}

.title-wrapper .left {
  margin-top: 14vh;
  width: 50%;
}

.title-wrapper .right {
  position: relative;
  margin-top: 20vh;
  width: 40%;
}

.cs {
  /* color: var(--csesoc-blue); */
  font-family: 'nevis', sans-serif;
}

.electives {
  font-family: 'nevis', sans-serif;
  letter-spacing: -0.25rem;

  background: linear-gradient(45deg, #ff0f7b, var(--csesoc-blue));

  background-clip: text;
  color: transparent;
}

.desc {
  font-size: 1.7rem;
}

@media only screen and (max-width: 990px) {
  .title-wrapper h1 {
    font-size: 8vw;
  }

}

.right .blob {
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 300%;
  transform: translate(-30%, -25%) rotate(-20deg) ;
  z-index: -1;
  
  background-image: url("../assets/blobs/vivian-blob.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70% 90%;
}

.right .feedback-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dev-message {
  margin-top: 8vh;
}

@media only screen and (max-aspect-ratio: 3/4) and (max-width: 480px) {
  .desc {
    font-size: 1.5rem;
  }

  .title-wrapper h1 {
    font-size: 16vw;
  }

  .title-wrapper .right {
    width: 80%;
    position: absolute;
    margin-left: 10vw;
    margin-top: 30vh;
  }
  .title-wrapper .left {
    margin-top: 0;
    width: 80%;
    margin-left: 5vw;
  }

  .start-reviewing {
    margin-top: 43vh;
    margin-left: 5vw;
    position: relative;
  }

  .right .blob {
    width: 150%;
    height: 300%;
    transform: translate(-20%, -26%) rotate(-20deg) ;
    background-size: 115%;
  }

  .sort-and-filter-container .dropdown-tags-box {
    display: flex;
    height: 3.5rem;
    margin-left: 1.1rem;
    position: relative;
    top: 45px;
    right: 300px;
    
  }

  .sort-and-filter-container .search-bar-wrapper {
    display: flex;
    white-space: nowrap;
  
  }

  .ui.segment {
    height: 180px;
  }

  .sort-dropdown-text {
    font-size: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .dev-message {
    position: relative;
    margin-top: 4.5vh;
    margin-left: 5vw;
  }

}

.search-bar-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
}

.filter-wrapper {
  display: flex;
}

.card-list-switch {
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: 10%;
}

@media only screen and (max-aspect-ratio: 3/4) {
  .card-list-switch {
    visibility: hidden;
  }
}

.switch-label {
  position: relative;
  margin: auto;
}

#search-section {
  padding-top: 2rem;
  align-items: center;
}


.no-results-found-div {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.no-results-found-centre {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin: auto;
  width: 50%;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.no-results-found-heading {
  font-size: x-large;
  padding: 1rem;

}

.no-results-found-subheading {
  color: #828F9B;
  padding-bottom: 5rem;
}

.no-results-found {
  width: 50%;
  margin: auto;
  text-align: center;
}

@media only screen and (max-aspect-ratio: 3/4) and (max-width: 480px) {
  .no-results-found-centre {
    width: 100%;
  }
}