.review-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 1.1rem;
}

.dropdown-reviews {
  flex-grow: 2;
  margin-left: 2rem;
  margin-right: 2rem;
}

.text-only-checkbox {
  margin-left: 2.5rem;
  margin-top: 1.1rem;
}

.no-reviews {
  column-count: 1;
  width: 100%;
  text-align: center;
  padding: 5rem;
}

.review-bars {
  margin: 0 2px;
}

.reviewCardAuthor {
  width: 100%;
  text-align: right;
}

.summary-stats {
  width: 60%;
}

.summary-card {
  overflow-x: hidden;
  overflow-y: auto scroll;
  position: -webkit-sticky;
  position: sticky;
  /* Pins sticky content to top of window */
  top: 20px;
  /* Prevents container of summary card overflowing past the bottom of window */
  height: 93vh;
}

.summary-card::-webkit-scrollbar {
  display: none;
}

.course-banner {
  /* background: url("../assets/bluebanner.png"); */
  height: 100%;
  /* background: linear-gradient(45deg, var(--primary), var(--primary-light)); */
  /* background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative; */
}

.review-cards {
  display: block;
  margin: 15px 20px;
}

.rating-only-right {
  text-align: right;
}

.icon-flag-outline {
  background: none;
  float: right;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.flag-submit-button {
  justify-content: center;
  display: flex;
  padding-top: 10px;
}

.flag-submission-message {
  text-align: center;
}

.ui.form.input.flag {
  display: block;
}

.other-input {
  padding-bottom: 10px;
}

.reviews {
  width: 100%;
  display: inline-block;
}

.review-cols {
  columns: 390px auto;
}

.submit-container {
  padding: 1rem;
  padding-top: 2rem;
}

.category-container {
  align-items: center;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.category-rating {
  font-size: 2.2rem;
  text-align: center;
  padding: 1rem 0;
  display: inline-block;
}

.category-small {
  display: inline-block;
  text-align: center;
  color: grey;
}

.review-ratings {
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;
}

.review-ratings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.review-description {
  white-space: pre-line;
}

.scroll-up-button {
  position: sticky;
  top: 90vh;
  padding-bottom: 3rem;
  /* bottom: 3rem; */
  z-index: 1;
  cursor: pointer;
  color: var(--csesoc-black);
}

.scroll-button-container {
  text-align: right;
  position: absolute;
  right: 3rem;
  height: 100%;
  padding-bottom: 5rem;
}

.star-ratings {
  display: flex;
  flex-direction: row;
  padding-top: 0.5rem;
}

.star-space {
  margin-right: 0.5rem;
}

.course-code-header {
  font-size: 3rem;
}

.tags {
  margin: 1rem 0;
}
.placeholder-ratings {
  padding: 3rem 0.5rem;
}

.blank {
  width: 60%;
  margin: auto;
  margin-top: 5rem;
}

.summary-desc {
  white-space: pre-line;
}

@media only screen and (max-aspect-ratio: 3/4) {
  .summary-card {
    overflow-x: hidden;
    overflow-y: hidden;
    top: 20px;
    width: 90%;
    margin: auto;
    height: fit-content;
  }

  .review-heading {
    flex-wrap: wrap;
  }

  .dropdown-reviews {
    width: 100%;
    margin: 1rem 0;
  }
}
