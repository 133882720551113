:root {
  --csesoc-blue: #3a76f8;
  --csesoc-navy: #40404c;
  --csesoc-white: #ffffff;
  --csesoc-black: #303030;

  --major-tags: #D5AAFF;
  --term-tags: #F7D6E0;
  --prefix-tags: #A3C5E1;
  --undergraduate: #B2F7EF;
  --postgraduate: #ACDEAA;

  --primary: #3A76F8;
  --primary-light: #CCE9FE;
  --primary-dark: #0D3B9F;

  --secondary: #9A4BFF;
  --secondary-light: #D9BBFF;
  --secondary-dark: #902ECC;

  --tertiary: #adddff;

  /* Traffic lights */
  --pink: #F3478F;
  --pink-light: #FDC8DE;
  --pink-dark: #B61A5C;

  --orange: #FCA119;
  --orange-light: #FFDDBD;
  --orange-dark: #F18200;

  --yellow: #FDE619;
  --yellow-light: #FFF9C3;
  --yellow-dark: #F6B700;

  --green: #B4EA1A;
  --green-light: #EFFDC7;
  --green-dark: #8FC100;

  --cyan: #1EEB95;
  --cyan-light: #C9FBDD;
  --cyan-dark: #00AA44;
}

@font-face {
  font-family: 'nevis';
  src: url('../assets/fonts/nevis.ttf');
}

@font-face {
  font-family: 'rubik';
  src: url('../assets/fonts/Rubik-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'clancy';
  src: url('../assets/fonts/Clancy-Bold.otf');
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fefefe;
}

main {
  position: relative;
}

body, main,
.ui.button,
.ui.text.container,
.ui.input > input,
.ui.list .list > .item .header,
.ui.list > .item .header,
.ui.steps .step .title,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"],
.ui.menu,
.ui.message .header,
.ui.cards > .card > .content > .header,
.ui.card > .content > .header,
.ui.items > .item > .content > .header,
.ui.statistics .statistic > .value,
.ui.statistic > .value,
.ui.statistics .statistic > .label,
.ui.statistic > .label,
.ui.accordion .title:not(.ui),
.ui.modal > .header,
.ui.popup > .header,
.ui.search > .results .result .title,
.ui.search > .results > .message .header {
  font-family: rubik;
}

.ui.header {
  font-family: clancy;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grid-row {
  margin: 0 25px;
}

.grid-top {
  margin: 0 25px;
  margin-top: 15px;
}

.grid-bottom {
  margin: 0 25px;
  margin-bottom: 15px;
}

.summary-card::-webkit-scrollbar {
  width: 5px;
}

.review-row {
  margin: 0 15px;
  margin-top: 3px;
}

.course-label {
  margin: 0 0 8px 10px;
  color: var(--csesoc-blue);
}

.ui.progress.bar-chart {
  margin: 5px 0;
}

.display-wrapper {
  min-height: 100vh;
}

.main-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

footer {
  background-color: var(--csesoc-blue);
  padding: 1.5rem;
  color: var(--csesoc-white);
}

.csesoc-logo {
  margin-bottom: 1.5rem;
}

.card-displayer {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.search-section-background {
  background-color: white !important;
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.course-review-card {
  display: flex;
  min-height: 230px;
  min-width: 345px;
  height: 100%;
  width: 100%;
}

.course-page-tags {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.front-page-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0rem;
  margin-bottom: 2.5rem;
}


.dropdown-tags {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-size {
  width: 30%;
}

.searchbar {
  margin-bottom: 1rem;
}

.ui.menu.secondary.pointing.item {
  float: left;
}

.review-row-ratings-only {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 110px;
  margin-left: 110px;
}

.ratings-only-review-header {
  margin-top: 0px;
}

.course-review-card-date {
  float: right;
}

.course-review-card-header {
  margin: 0px 335px 0px 0;
}

.course-review-card-star-rating {
  margin: 5px 0;
}

.searchbar-dropdown {
  width: 250px;
}

.ui.label {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0;
}

.ui.dropdown .menu .item:hover {
  background: #F2F2F2;
}

.logo {
  width: 45px;
}