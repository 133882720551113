.required {
    color: red;
}

.overall-rating {
    text-align: center;
    margin-right: 10rem;
}

.easterEgg{
    color: white;
}

.question-spacing {
    line-height: 295%;
}

.review-anon-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.review-term-text {

    padding-top: 1rem;
    margin-bottom: 1rem;
}

.review-button {
    text-align: right;
    margin-top: 2rem;
}

.course-ratings {
    flex-grow: 1;
}

.review-form-left {
    flex-grow: 1;
    margin-left: 3rem;
}

.review-modal {
    padding-top: 1.125rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 1.125rem;
}

.review-toggle {
    padding-top: 0.8rem;
    padding-bottom: 0.725rem;
}

.info-hover {
    padding-left: 0.6rem;
}