.not-found-page-background {
    background: url("../assets/not-found.jpg");
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: local;
    background-position: center;
    z-index: -5;
  }
  
  .not-found-page-text {
    position: absolute;
    top: 9%;
    right: 6%;
    color: var(--csesoc-white);
    font-size: 34px;
    text-align: center;
    font-family: "Lucida Console", monospace;
    max-width: 59rem;
    margin-top: 7rem;
    line-height: 3.5rem;
    font-weight: bold;
  }
  .not-found-page-text > div {
    margin-bottom: 4.5rem;
  }
  